import VXButton                 from "../VXButton";
import React                    from 'react';
import PropTypes                from 'prop-types';
import {VXButtonLineConfig, VXButtonLineStyle}     from "../VXButtonConfig";
import STYLES                   from './../EnumVXButton';
import {COLOR_TYPE_VIDEOCALL}   from "../ButtonHelper";
import Flux                     from "../../../../flux/Flux";
import { getCommonTranslation } from "../../../../utils/TranslationHelper";
import { formatChatPrice }      from "../../../../utils/CommonUtils";

class VXButtonPrivateVideoCall extends React.PureComponent {
	render() {
        const classes = ["vxbutton__private-videocall-wrapper"];
		return (
            <div className={classes.join(" ")} data-plae="top">
                <VXButton color={COLOR_TYPE_VIDEOCALL}
                          line1={[new VXButtonLineConfig(getCommonTranslation('videocall-button__text'), new VXButtonLineStyle([STYLES.UPPERCASE]))]}
                          line2={[new VXButtonLineConfig(Flux.Guest.isLoggedIn() ? formatChatPrice(this.props.price) : getCommonTranslation('videocall-button__start'))]}
                          icon={'-icon-private-videocall-filled'}
                          onClick={this.props.onClick}
                          staticMode={true}
                          iconPosition={STYLES.ICON_POSITION.LEFT}
                          size={this.props.size}
                          toolTip={getCommonTranslation('videocall-tooltip')}
                />
            </div>
		);
	}
}

VXButtonPrivateVideoCall.propTypes = {
	onClick:    PropTypes.func.isRequired,
	price:      PropTypes.number.isRequired,
	size:       PropTypes.oneOf(Object.values(STYLES.SIZE)),
};

VXButtonPrivateVideoCall.defaultProps = {
	size:       STYLES.SIZE.MEDIUM,
};

export default VXButtonPrivateVideoCall;