import React from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			error,
		};
	}

	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	componentDidCatch(error, info) {
		this.props.showError && console.log(error, info);
	}

	render() {
		if (this.state.hasError) {
			return this.props.fallback;
		}

		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node,
	fallback: PropTypes.any,
	showError: PropTypes.bool,
};

ErrorBoundary.defaultProps = {
	fallback: null,
	showError: false,
};

export default ErrorBoundary;
