import React             from 'react';
import PropTypes         from 'prop-types';
import Flux              from '../../flux/Flux';
import VideochatButton   from './VideochatButton';
import Translations      from "../../utils/Translations";
import withErrorBoundary from '../HigherOrderComponents/Utility/withErrorBoundary';
import LivePreviewButton from './LivePreviewButton';
import VXButtonPrivateVideoCall from './VXButton/Presets/VXButtonPrivateVideoCall';
import { VXPay, VXPayAction }   from '../../utils/VXPay';
import { TYPES }                from '../Loader/Loader';
import ReloadHelper             from '../../utils/ReloadHelper';
import STYLES                   from './VXButton/EnumVXButton';

class VideochatButtonWrapper extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            buttonData: Flux.Button.getButtonConfigByActorId(this.props.actorId) || null,
        };

        this.onButtonDataChanged         = this.onButtonDataChanged.bind(this);
        this.onOnlineStateChange         = this.onOnlineStateChange.bind(this);
        this.getVideochatMessengerButton = this.getVideochatMessengerButton.bind(this);
        this.getActorTileVideoChatButton = this.getActorTileVideoChatButton.bind(this);
    }

    componentDidMount() {
        Flux.Notification.addNotificationChangeListener(this.onOnlineStateChange);
        Flux.Button.addButtonChangeListener(this.onButtonDataChanged);
    }

    componentWillUnmount() {
        Flux.Notification.removeNotificationChangeListener(this.onOnlineStateChange);
        Flux.Button.removeButtonChangeListener(this.onButtonDataChanged);
    }

    onButtonDataChanged() {
        const buttonData = Flux.Button.getButtonConfigByActorId(this.props.actorId);
        if (!buttonData) {
            return null;
        }
        this.setState({buttonData});
    }

    onOnlineStateChange(notification) {
        const NotificationTypes = Flux.Constants.ApiTypes.Notification.Incoming;
        switch (notification.type) {
            case NotificationTypes.ACTOR_ONLINE:
                if (notification.actorId === this.props.actorId) {
                    let isCurrentlyTicketShow = this.state.isCurrentlyTicketShow;

                    if (this.state.isLobbyChat && !notification.isLobbyChat) {
                        isCurrentlyTicketShow = false;
                    }
                    this.setState({buttonData: {
                        ...this.state.buttonData,
                        isActorOnline:           true,
                        isVoyeurChatAvailable:   notification.canVoyeurChat,
                        isLobbyChat:             notification.isLobbyChat,
                        isCurrentlyTicketShow,
                        isOnlineMobileVideocall: false,
                    }});
                }
                break;
            case NotificationTypes.ACTOR_OFFLINE:
                if (notification.actorId === this.props.actorId) {
                    this.setState({buttonData: {
                        ...this.state.buttonData,
                        isActorOnline:           false,
                        isLobbyChat:             false,
                        isCurrentlyTicketShow:   false,
                        isOnlineMobileVideocall: notification.isOnlineMobileVideocall,
                        singleC2CChatPrice:      notification.singleC2CChatPrice,
                    }});
                }
                break;
            case NotificationTypes.ACTOR_SETTINGS:
                if (notification.actorId === this.props.actorId) {
                    this.setState({buttonData: {
                        ...this.state.buttonData,
                        isLivePreviewEnabled: notification.isLivePreviewEnabled,
                    }});
                }
                break;
            case NotificationTypes.FREE_SHOW_START:
                if (notification.actorId === this.props.actorId) {
                    this.setState({buttonData: {
                        ...this.state.buttonData,
                        freeShowData: notification.data,
                }});
                }
                break;
            case NotificationTypes.FREE_SHOW_STOP:
                if (notification.actorId === this.props.actorId) {
                    this.setState({buttonData: {
                        ...this.state.buttonData,
                        freeShowData: null,
                    }});
                }
                break;
            case NotificationTypes.VOICECALL_START :
                if (Flux.Guest.isLoggedIn()) {
                    this.setState({buttonData: {
                        ...this.state.buttonData,
                        isVoicecallActive: true,
                    }});
                }
                break;
            case NotificationTypes.VOICECALL_STOP :
                if (Flux.Guest.isLoggedIn()) {
                    this.setState({buttonData: {
                        ...this.state.buttonData,
                        isVoicecallActive: false,
                    }});
                }
                break;
            case NotificationTypes.SERVICE0900_STATUS_CHANGED :
                if (notification.actorId === this.props.actorId) {
                    this.setState({buttonData: {...this.state.buttonData, isService0900Busy: notification.isBusy}});
                }
                break;
            default:
                break;
        }
    }

    isConversionChatAvailable(data) {
        return data.isActorAvailableForConversionChat && Flux.Guest.isEligibleForConversionChat();
    }

    getActorProfileLivePreview(data) {
        return <LivePreviewButton
                {...data}
                additionalClassName={'actor-profile-header__btn-live-preview h-left'}
                isEnabled={data.isLivePreviewEnabled}
            />;
    }

    getActorProfileLivePreviewSmall(data) {
        return data.isLivePreviewEnabled && data.isActorOnline && <LivePreviewButton
                {...data}
				useStandaloneIcon={false}
				isEnabled={data.isLivePreviewEnabled}
				additionalClassName=' -size-sm'
			/>;
    }

    onVideocallClick() {
        if (Flux.Guest.isLoggedIn()) {
            if (this.state.buttonData.actorLink) {
                ReloadHelper.reload(this.state.buttonData.actorLink);
            } else {
                Flux.Special.showLoader(TYPES.DOT);
                Flux.NewMessenger.startChat(Flux.Constants.VXMessenger.ChatTypes.VIDEOCALL, this.props.actorId);
            }
        } else {
            // special logic for sender landing page: only redirect to profile instead of really executing an action (hard redirect also for mobile!)
            if (this.state.buttonData.actorLink) {
                VXPay.openSignupOrLogin({},
                        new VXPayAction(Flux.Constants.ActionTypes.VXPay.REDIRECT_TO, {
                            targetUrl: this.state.buttonData.actorLink,
                        }));
            } else {
                VXPay.openSignupOrLogin();
            }
        }
    }

    getPrivateVideoCall(isSmall = false) {
        return <VXButtonPrivateVideoCall
                price={this.state.buttonData?.prices?.singlec2c}
                onClick={this.onVideocallClick}
                size={isSmall ? STYLES.SIZE.SMALL : STYLES.SIZE.MEDIUM}
        />;
    }

    getVideochatButton(data) {
        return <VideochatButton {...data} />;
    }

    getChatExitButton(data) {
        return this.getVideochatButton({...data, tooltipText: Translations.get('ChatButtonBackToChat'), additionalClassNames: "-size-l"});
    }

    getActorProfileVideochatButton(data) {
        return this.getVideochatButton({...data,
            additionalClassNames: 'actor-profile-header__btn actor-profile-header__btn-videochat h-left' + ((!Flux.Guest.isVoicecallActive() && data.isLivePreviewEnabled) ? ' -live-preview-enabled' : ''),
            tooltipText: Translations.get('TooltipVideoChatButton')
        });
    }

    getActorProfileVoyeurchatButton(data) {
        return data.isVoyeurChatAvailable && this.getVideochatButton({...data,
                multiChatPrice: data.prices?.voyeur || 0,
                isVoyeurMode: true,
                additionalClassNames: 'actor-profile-header__btn-voyeurchat h-left' + ((!Flux.Guest.isVoicecallActive() && data.isLivePreviewEnabled && !this.isConversionChatAvailable(data)) ? ' -live-preview-enabled' : ''),
                tooltipText: Translations.get('TooltipVoyeurChatButton')
        });
    }

    getActorPlateVideochatButton(data) {
        return this.getVideochatButton({...data,
            additionalClassNames: '-click-material h-width-full',
            tooltipText: data.isOnlineMobileVideocall ? Translations.get('common.videocall-tooltip') : '',
            useStandaloneIcon: false
        });
    }

    getActorPlateExtendedVideochatButton(data) {
        return this.getVideochatButton({...data,
                tooltipText: data.isOnlineMobileVideocall ? Translations.get('common.videocall-tooltip') : '',
                additionalClassNames: 'actor-search__videochat-button',
                redirectOnProfileLink: false,

        });
    }

    getActorPlateExtendedLightVideochatButton(data) {
        return this.getVideochatButton({...data,
                tooltipText: data.isOnlineMobileVideocall ? Translations.get('common.videocall-tooltip') : '',
                additionalClassNames: 'actor-search__videochat-button',
                redirectOnProfileLink: false,
                pfofileLink: data.actorLink,
                isOnlineMobileVideocallMargin: true
        });
    }

    getVideochatMessengerButton(data) {
        return this.getVideochatButton({...data,  profileLink: data.actorLink});
    }

    getActorTileVideoChatButton(data) {
        return this.getVideochatButton({...data,  profileLink: data.actorLink, useStandaloneIcon: false, additionalClassNames: "-click-material -size-sm -size-actortiles h-text-align-center"});
    }

    render() {
        if (!this.state.buttonData) {
            return null;
        }
        const data = this.state.buttonData;
        if (this.props.gaString) {
            data.gaString = this.props.gaString;
        }
        data.isGuestLoggedIn = Flux.Guest.isLoggedIn();
        let content = null;

        switch (this.props.type) {
            case Flux.Constants.VideochatButtonTypes.actorProfileVideochat:
                content = this.getActorProfileVideochatButton(data);
                break;
            case Flux.Constants.VideochatButtonTypes.actorProfileVoyeurchat:
                content = this.getActorProfileVoyeurchatButton(data);
                break;
            case Flux.Constants.VideochatButtonTypes.actorProfileLivePreview:
                content = this.getActorProfileLivePreview(data);
                break;
            case Flux.Constants.VideochatButtonTypes.actorProfileLivePreviewSmall:
                content = this.getActorProfileLivePreviewSmall(data);
                break;
            case Flux.Constants.VideochatButtonTypes.actorProfilePrivateVideoCall:
                content = this.getPrivateVideoCall();
                break;
            case Flux.Constants.VideochatButtonTypes.actorProfilePrivateVideoCallSmall:
                content = this.getPrivateVideoCall(true);
                break;
            case Flux.Constants.VideochatButtonTypes.actorPlateVideochat:
                content =  this.getActorPlateVideochatButton(data);
                break;
            case Flux.Constants.VideochatButtonTypes.actorPlateExtendedLightVideochat:
                content =  this.getActorPlateExtendedLightVideochatButton(data);
                break;
            case Flux.Constants.VideochatButtonTypes.actorPlateExtendedVideochat:
                content =  this.getActorPlateExtendedVideochatButton(data);
                break;
            case Flux.Constants.VideochatButtonTypes.chatExitButton:
                content =  this.getChatExitButton(data);
                break;
            case Flux.Constants.VideochatButtonTypes.videochatButton:
                content =  this.getVideochatButton(data);
                break;
            case Flux.Constants.VideochatButtonTypes.videochatMessengerButton:
                content =  this.getVideochatMessengerButton(data);
                break;
            case Flux.Constants.VideochatButtonTypes.actorTile:
                content =  this.getActorTileVideoChatButton(data);
                break;
        }

        return content;
    }
}

VideochatButtonWrapper.propTypes = {
    actorId:   PropTypes.number.isRequired,
    type:      PropTypes.string.isRequired,
    gaString:  PropTypes.string,
};

export default withErrorBoundary(VideochatButtonWrapper);
