import React         from 'react';
import ErrorBoundary from '../../../utils/ErrorBoundary';

function withErrorBoundary(WrappedComponent) {
	class withErrorBoundary extends React.Component {
		render() {
			return <ErrorBoundary><WrappedComponent {...this.props} /></ErrorBoundary>;
		}
	}
	return withErrorBoundary;
}

export default withErrorBoundary;
